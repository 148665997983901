import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO2;

/**
 * 全民品控上报列表
 * @param {*} params
 */
export const postFleetQualityControlList = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/list`, params);
};

/**
 * 修改提交数据
 * @param {*} params
 */
export const postFleetQualityControlUpdate = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/update`, params);
};

/**
 * 审核
 * @param {*} params
 */
export const postFleetQualityControlAudit = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/audit`, params);
};

/**
 * 【品控录入】编辑页面回显数据
 * @param {*} params
 */
export const postFleetQcDetail = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/qc/detail`, params);
};

/**
 * 【品控录入】品控问题类型
 * @param {*} params
 */
export const postFleetQcType = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/qc/type`, params);
};
